<!--
 * @Description: 维修任务单
 * @Author: ChenXueLin
 * @Date: 2021-10-12 13:39:18
 * @LastEditTime: 2023-02-20 16:22:02
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 基本信息 start -->
          <template>
            <div class="primaryTitle">维修任务单</div>
            <div class="baseInfo-box">
              <div class="edit-title">基本信息</div>
              <el-form
                ref="baseInfoForm"
                class="editForm"
                label-position="right"
                :inline="true"
                label-width="120px"
                :rules="baseInfoFormRules"
                :model="baseInfoForm"
              >
                <el-form-item label="客户名称" prop="corpId">
                  <e6-vr-select
                    v-model="baseInfoForm.corpId"
                    :data="corpList"
                    :disabled="queryId ? true : false"
                    placeholder="客户名称"
                    title="客户名称"
                    clearable
                    virtual
                    remote
                    :is-title="true"
                    @filterChange="handleLoadCorpFilter"
                    @change="handleChange"
                    :props="{
                      id: 'corpId',
                      label: 'corpName'
                    }"
                  ></e6-vr-select>
                </el-form-item>
                <el-form-item label="联系人" prop="linkmanrpid">
                  <div class="select-content">
                    <e6-vr-select
                      v-model="baseInfoForm.linkmanrpid"
                      :data="contactList"
                      placeholder="联系人"
                      title=""
                      :props="{
                        id: 'contactId',
                        label: 'contactName'
                      }"
                      clearable
                      @change="handleContact"
                      @clear="handleClear"
                    ></e6-vr-select>
                    <el-button
                      type="text"
                      style="margin-left: 10px"
                      @click="addConcat"
                    >
                      添加联系人
                    </el-button>
                  </div>
                </el-form-item>
                <el-form-item label="联系电话" prop="contactPhone">
                  <el-input
                    placeholder="联系电话"
                    v-model="baseInfoForm.contactPhone"
                  ></el-input>
                </el-form-item>
                <el-form-item label="联系地址" prop="defaultCity">
                  <e6-vr-select
                    style="width:300px"
                    v-model="baseInfoForm.defaultCity"
                    :data="addressList"
                    placeholder="联系地址"
                    title="联系地址"
                    virtual
                    clearable
                    :is-title="true"
                    remote
                    @filterChange="handleLoadAddressFilter"
                    @change="handleCitySelect"
                    :props="{
                      id: 'fullName',
                      label: 'fullName'
                    }"
                  ></e6-vr-select>
                </el-form-item>
                <el-form-item label="详细地址" prop="insAddress">
                  <el-input
                    placeholder="详细地址"
                    v-model="baseInfoForm.insAddress"
                  ></el-input>
                </el-form-item>
                <el-form-item label="要求完成时间" prop="expecttime">
                  <el-date-picker
                    v-model="baseInfoForm.expecttime"
                    type="datetime"
                    placeholder="选择日期"
                    value-format="timestamp"
                    popper-class="special"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                  <el-input
                    v-model="baseInfoForm.remark"
                    placeholder="请输入备注"
                    type="textarea"
                    :rows="3"
                    style="width: 500px"
                    maxlength="100"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </template>
          <!-- 基本信息 end -->
          <!-- 任务项设置   start -->
          <template>
            <div class="edit-title">任务项设置</div>
            <div class="edit-content" style="margin-bottom: 20px">
              <div class="associated-info">
                <div class="right-button">
                  <el-button type="primary" @click="addScene">
                    添加任务项
                  </el-button>
                  <el-button type="primary" @click="handleDelete">
                    删除任务项
                  </el-button>
                  <el-button type="primary" @click="handleWrite">
                    批量填写
                  </el-button>
                </div>
              </div>
              <el-table
                ref="multipleTable"
                :data="tableData"
                highlight-current-row
                @selection-change="handleSelectionChange"
              >
                <el-table-column type="selection" width="50"></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="sceneName"
                  label="场景名称"
                  width="160"
                  align="center"
                  fixed
                  header-align="center"
                >
                  <template slot-scope="{ row }">
                    <span v-if="row.show">{{ row.sceneName }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="secondClassName"
                  label="安装组合"
                  width="160"
                  align="center"
                  header-align="center"
                >
                  <template slot-scope="{ row }">
                    <span v-if="row.show2">{{ row.secondClassName }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="thirdClassName"
                  label="已安装商品"
                  width="160"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="firstClassName"
                  label="商品分类"
                  width="160"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="equipCode"
                  label="设备编号"
                  width="160"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="virtualTypeName"
                  label="类型"
                  width="160"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="problemType"
                  label="问题类型"
                  width="200"
                  align="center"
                  header-align="center"
                >
                  <template slot-scope="scope">
                    <div v-if="scope.row.show">
                      <el-button
                        type="text"
                        @click="slectQues(scope.row, scope.$index)"
                      >
                        {{
                          scope.row.problemType
                            ? scope.row.problemType
                            : "选择问题类型"
                        }}
                      </el-button>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="problemType"
                  label="问题描述"
                  width="200"
                  align="center"
                  header-align="center"
                >
                  <template slot-scope="scope">
                    <el-input
                      v-if="scope.row.show"
                      v-model="scope.row.quesName"
                      @input="handleInput(scope)"
                      maxlength="50"
                    ></el-input>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </div>
        <div class="edit-wrapper__footer">
          <el-button class="cancel" @click="handleGoBack">取消</el-button>
          <el-button type="primary" @click="submit">确定</el-button>
        </div>
      </section>
      <!-- 选择场景 -->
      <select-scene
        :selectSceneDialog="selectSceneDialog"
        @handleClose="handleClose"
        @getData="getData"
        :corpId="baseInfoForm.corpId"
      ></select-scene>
      <!-- 添加联系人弹框 -->
      <add-concat-dialog
        :addContactVisible="addContactVisible"
        @handleClose="handleClose"
        @updateList="updateList"
        :isNeedReq="true"
        :corpName="baseInfoForm.corpName"
        :corpId="baseInfoForm.corpId"
      ></add-concat-dialog>
      <!-- 批量填写弹框 -->
      <el-dialog
        v-dialogDrag
        title="批量填写"
        :visible.sync="writeDescVisible"
        width="500px"
        :before-close="closeDialog"
        :close-on-click-modal="false"
        :element-loading-background="loadingBackground"
        custom-class="write-dialog"
      >
        <el-form
          ref="writeDescForm"
          :model="writeDescForm"
          :rules="writeDescFormRules"
          :inline="true"
        >
          <el-form-item label="问题描述" prop="desc">
            <el-input
              v-model.trim="writeDescForm.desc"
              placeholder="问题描述"
              maxlength="50"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="dialog-footer" slot="footer">
          <el-button class="cancel" @click="closeDialog">取消</el-button>
          <el-button type="primary" @click="confirmWrite">确定</el-button>
        </div>
      </el-dialog>
      <!-- 问题类型弹框 -->
      <el-dialog
        v-dialogDrag
        title="问题类型"
        :visible.sync="quesTypeVisible"
        width="600px"
        :before-close="closeQuesDialog"
        :close-on-click-modal="false"
        :element-loading-background="loadingBackground"
        custom-class="write-dialog"
      >
        <el-form
          ref="questionForm"
          :model="questionForm"
          :rules="questionFormRules"
          :inline="true"
        >
          <el-form-item label="一级问题类型" prop="firstQuesType">
            <e6-vr-select
              :data="firstQuesList"
              v-model="questionForm.firstQuesType"
              placeholder="一级问题类型"
              title="一级问题类型"
              clearable
              :props="{
                id: 'id',
                label: 'errorReason'
              }"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item label="二级问题类型" prop="secondQuesType">
            <e6-vr-select
              :data="secondQuesList"
              :loading="dialogLoading"
              v-model="questionForm.secondQuesType"
              placeholder="二级问题类型"
              title="二级问题类型"
              :props="{
                id: 'id',
                label: 'errorReason'
              }"
              clearable
            ></e6-vr-select>
          </el-form-item>
        </el-form>
        <div class="dialog-footer" slot="footer">
          <el-button class="cancel" @click="closeQuesDialog">取消</el-button>
          <el-button type="primary" @click="confirmSelect">确定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import SelectScene from "@/components/workOrderManage/selectScene.vue";
import AddConcatDialog from "@/components/addConcatDialog.vue";
import addTask from "@/mixins/addTask";
import { getQuestion, createRepairTask } from "@/api";
import { printError } from "@/utils/util";
import { telAndMobileValid } from "@/utils/validate";
export default {
  name: "repairTaskOrder",
  components: { SelectScene, AddConcatDialog },
  data() {
    return {
      nowDate: new Date().setHours(new Date().getHours() + 4),
      loading: false,
      workSecondClass: "10",
      isConfirm: 1,
      corpList: [], //客户名称下拉框
      concatList: [], //联系人下拉框
      baseInfoForm: {
        corpId: "", //公司id
        contactName: "",
        insAddress: "", //详细地址
        linkmanrpid: "", //联系人id
        contactPhone: "", //电话
        defaultCity: "", //默认选中地址
        remark: "",
        expecttime: new Date().setHours(new Date().getHours() + 4)
      }, //基本信息
      baseInfoFormRules: {
        corpId: [
          {
            required: true,
            message: "请选择客户名称",
            trigger: ["blur", "change"]
          }
        ],
        linkmanrpid: [
          {
            required: true,
            message: "请选择联系人名称",
            trigger: ["blur", "change"]
          }
        ],
        expecttime: [
          {
            required: true,
            message: "请选择解决时间",
            trigger: ["blur", "change"]
          }
        ],
        insAddress: [
          {
            required: true,
            message: "请输入详细地址",
            trigger: ["blur", "change"]
          }
        ],
        contactPhone: telAndMobileValid.required({
          requiredMsg: "请输入手机号码",
          message: "号码格式不正确",
          trigger: ["blur", "change"]
        }),
        defaultCity: [
          {
            required: true,
            message: "请选择地址",
            trigger: ["blur", "change"]
          }
        ]
      },
      /*****问题类型******/
      dialogLoading: false,
      firstQuesList: [], //一级问题下拉框
      secondQuesList: [], //二级问题下拉框
      quesTypeVisible: false,
      questionForm: {
        firstClassId: "", //设备分类
        firstQuesType: "", //一级问题
        secondQuesType: "" //二级问题
      },
      questionFormRules: {
        firstQuesType: [
          {
            required: true,
            message: "请选择一级问题类型",
            trigger: ["blur", "change"]
          }
        ],
        secondQuesType: [
          {
            required: true,
            message: "请选择二级问题类型",
            trigger: ["blur", "change"]
          }
        ]
      },
      currentIndex: "", //点击的当前行
      addContactVisible: false, //添加联系人弹框
      //批量填写弹框
      clickInputValue: "", //问题描述上一次填写的值
      clickInputIndex: "", //批量填写前的index
      selectCloumn: [], //要填写的数据
      writeDescVisible: false,
      writeDescForm: {
        desc: ""
      },
      writeDescFormRules: {
        desc: [
          {
            required: true,
            message: "请输入问题描述",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  mixins: [base, addTask],
  created() {
    // this.baseInfoForm.expecttime = new Date().setHours(
    //   new Date().getHours() + 4
    // );
  },

  watch: {
    // 监听一级分类选择更改
    "questionForm.firstQuesType": {
      immediate: true,
      handler(val, oldVal) {
        if (val && val != oldVal) {
          this.questionForm.secondQuesType = "";
          this.queryQuestion(val);
        } else {
          this.secondQuesList = [];
          this.questionForm.secondQuesType = "";
        }
      }
    }
  },
  methods: {
    // handleSelectionChange(val) {
    //   console.log(val, "val---");
    // },
    //客户下拉框处理逻辑
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        this.baseInfoForm.corpId = "";
        this.loadCorpData(val);
      }
    }, 300),
    //添加联系人之后更新下拉框
    updateList() {
      this.getContactList();
    },
    //点击确定
    submit() {
      this.$refs.baseInfoForm.validate(valid => {
        if (valid) {
          if (this.baseInfoForm.expecttime < this.nowDate) {
            this.$message.warning("要求完成时间不能小于当前时间+4小时");
            return;
          }
          // if (!this.baseInfoForm.insCountyName) {
          //   this.$message.warning("区县不能为空");
          //   return;
          // }
          this.baseInfoForm.contactName = this.filterSelect(
            this.contactList,
            "contactId",
            this.baseInfoForm.linkmanrpid
          ).contactName;

          if (!this.tableData.length) {
            this.$message.warning("请至少选择一个任务项");
            return;
          }
          //校验问题类型和问题描述是否全写
          let ruleArr = this.tableData.filter(item => item.show);
          if (ruleArr.some(each => !each.firstQuesType)) {
            this.$message.warning("请检查问题类型是否全部选择");
            return;
          }
          if (ruleArr.some(each => !each.quesName)) {
            this.$message.warning("请检查问题描述是否全部填写");
            return;
          }
          this.checkCreateTaskReq();
        }
      });
    },

    //创建维修单请求
    async createTaskReq() {
      try {
        this.loading = true;
        let res = await createRepairTask({
          ...this.baseInfoForm,
          isConfirm: this.isConfirm,
          taskItemReqVOList: this.tableData
        });

        if (res.code == "OK") {
          this.$message.success("创建成功");
          this.closeTag(this.$route);
          this.handleGoBack(true);
          // this.routerPush({
          //   name: "taskListManage/allTaskList",
          //   params: {
          //     refresh: true
          //   }
          // });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    /********问题类型选择***********/
    slectQues(row, index) {
      this.questionForm.firstClassId = row.firstClassId;
      this.currentIndex = index;
      this.quesTypeVisible = true;
      let filterArr = this.tableData.filter(item => {
        return item.sceneId == row.sceneId;
      });
      this.firstClassId = filterArr.map(item => {
        return item.firstClassId;
      }); //商品分类id
      console.log(this.questionForm.firstClassId, this.firstClassId, "===");
      this.queryQuestion(0);
    },
    //获取一二级问题类型
    async queryQuestion(pid) {
      try {
        this.dialogLoading = true;
        let res = await getQuestion({
          firstClassIdList: this.firstClassId,
          parentId: pid
        });
        if (pid == 0) {
          this.firstQuesList = this.getFreezeResponse(res, {
            path: "data"
          });
        } else {
          this.secondQuesList = this.getFreezeResponse(res, {
            path: "data"
          });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //确定选中问题类型
    confirmSelect() {
      this.$refs.questionForm.validate(valid => {
        if (valid) {
          //  给table赋值
          this.tableData[
            this.currentIndex
          ].firstQuesType = this.questionForm.firstQuesType;
          this.tableData[
            this.currentIndex
          ].secondQuesType = this.questionForm.secondQuesType;
          this.tableData[
            this.currentIndex
          ].firstQuesTypeName = this.firstQuesList.filter(
            item => item.id == this.questionForm.firstQuesType
          )[0].errorReason;
          this.tableData[
            this.currentIndex
          ].secondQuesTypeName = this.secondQuesList.filter(
            item => item.id == this.questionForm.secondQuesType
          )[0].errorReason;
          this.tableData[this.currentIndex].problemType =
            this.tableData[this.currentIndex].firstQuesTypeName +
            "-" +
            this.tableData[this.currentIndex].secondQuesTypeName;
          this.closeQuesDialog();
        }
      });
    },
    //关闭问题类型选择框
    closeQuesDialog() {
      this.questionForm = {
        firstClassId: "", //设备分类
        firstQuesType: "", //一级问题
        secondQuesType: "" //二级问题
      };
      this.$refs.questionForm.resetFields();
      this.quesTypeVisible = false;
    },
    /********问题描述填写*************/
    handleInput(scope) {
      this.clickInputValue = scope.row.quesName;
      this.clickInputIndex = scope.$index;
    },
    //点击批量填写
    handleWrite() {
      if (this.clickInputValue) {
        this.tableData.map((item, index) => {
          console.log(this.clickInputIndex);
          if (index >= this.clickInputIndex) {
            item.quesName = this.clickInputValue;
          }
        });
      }
    },
    //确认填写
    confirmWrite() {
      this.selectCloumn.map(item => {
        item.quesName = this.writeDescForm.desc;
      });
      this.closeDialog();
    },
    //关闭批量填写
    closeDialog() {
      this.writeDescForm = {
        desc: ""
      };
      this.selectCloumn = [];
      this.$refs.multipleTable.clearSelection();
      this.$refs.writeDescForm.resetFields();
      this.writeDescVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.edit-content {
  padding: 20px 20px 2px;
  .e6-vr-select {
    width: 100%;
  }
}
.associated-info {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
.table-input {
  width: 300px;
}
/deep/.write-dialog {
  .el-dialog__body {
    padding: 20px;
    .el-input {
      width: 220px;
    }
  }
}
/deep/.edit-address {
  .el-form-item__label {
    &::before {
      content: "*";
      color: #f56c6c;
      margin-right: 4px;
    }
  }
}
</style>
